@font-face {
  font-family: 'Open Sans';
  src: url('../../../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf') format("truetype-variations");
  font-style: normal;
  font-weight: 100 1000;
  font-stretch: normal;
  font-variation-settings: 'wdth' 95;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../../assets/fonts/OpenSans-Italic-VariableFont_wdth,wght.ttf') format("truetype-variations");
  font-style: italic;
  font-weight: 100 1000;
  font-stretch: normal;
  font-variation-settings: 'wdth' 95;
}

@font-face {
  font-family: 'RedHatText';
  src: local('RedHatText-Bold'),
  url(../../../assets/fonts/RedHatText-Bold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'RedHatText';
  src: local('RedHatText-Medium'),
  url(../../../assets/fonts/RedHatText-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'RedHatText';
  src: local('RedHatText'),
  url(../../../assets/fonts/RedHatText-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'),
  url(../../../assets/fonts/Roboto-Medium.ttf) format('truetype');
}
