h1 {
  font-family: 'RedHatText', sans-serif;
  font-weight: bold;
  font-size: 64px;
}

h2 {
  font-family: 'RedHatText', sans-serif;
  font-weight: bold;
  font-size: 48px;
}

h3 {
  font-family: 'RedHatText', sans-serif;
  font-weight: bold;
  font-size: 32px;
}

h4 {
  font-family: 'RedHatText', sans-serif;
  font-weight: bold;
  font-size: 24px;
}

h5 {
  font-family: 'RedHatText', sans-serif;
  font-weight: 500;
  font-size: 18px;
}

h6 {
  font-family: 'RedHatText', sans-serif;
  font-weight: 500;
  font-size: 14px;
}

p {
  font-family: 'Open Sans', sans-serif;
}

html,
body,
#root {
  height: 100%;
  overflow: auto;
  min-width: 1024px;
  font-size: 14px;
  background: #FFF;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

.grecaptcha-badge {
  visibility: hidden;
}
